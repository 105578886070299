.nav {
    padding: 20px 0 70px;
    height: 100%;
    overflow: auto;
  }
  .nav ul {
    list-style: none;
    display: block;
    margin: 0;
    padding: 0;
  }
  
  .nav li {
    font-size: 14px;
    position: relative;
    margin-bottom: 6px;
  }
  
  .nav ul li a,
  .nav ul li button {
    color:black;
    text-decoration: none;
    display: block;
    padding: 10px 20px 10px 20px;
    background-color: transparent;
    border: 0;
    width: 100%;
    text-align: left;
    transition: all 0.3s ease-in-out;
  }
  
  .nav ul li:hover a,
  .nav ul li:hover button {
    color: black;
    transform: translate(0, -2px);
  }
  
  .nav ul li.active:hover a,
  .nav ul li.active:hover button {
    transform: translate(0, 0);
  }
  
  .nav > ul > li > a,
  .nav > ul > li > button {
    font-size: 1em;
    cursor: pointer;
  }
  
  .nav > ul > li > a > svg,
  .nav > ul > li > button > svg {
    vertical-align: middle;
    margin-right: 16px;
  }
  
  .nav li ul {
    max-height: 0px;
    margin-left: 10px;
    overflow: hidden;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  }
  
  .nav > ul > li.active::before {
    content: "";
    position: absolute;
    width: 6px;
    height: calc(100% - 25px);
    background-color: #3489d1;
    left: 0;
    top: 14px;
  }
  
  .nav > ul > li.active > a {
    color: #3489d1;
  }
  
  .nav > ul > li.active svg {
    color: #3489d1;
  }
  